import React, { FC, memo, useState } from 'react';
import { ReactComponent as EmailIcon } from 'application/assets/email.svg';
import { ReactComponent as SearchIcon } from 'application/assets/search.svg';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ReactComponent as EyeOpenIcon } from 'application/assets/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from 'application/assets/eye-closed.svg';
import { ReactComponent as AttentionIcon } from 'application/assets/attention.svg';
import { ReactComponent as InfoIcon } from 'application/assets/info.svg';
import { InputProps } from './models';
import { InputWrapper, StyledInput, LabelWrapper } from './styled';

const Input: FC<InputProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  type,
  register,
  isFocused,
  onChange,
  onClear,
  value,
  autoComplete,
  showRequiredIcon,
  isOnlyError = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledInput
      isError={!!errorMessage || isOnlyError}
      disabled={disabled}
      type={type}
    >
      {label && (
        <LabelWrapper>
          <label htmlFor={id}>{label}</label>
          {showRequiredIcon && <InfoIcon />}
        </LabelWrapper>
      )}
      <InputWrapper disabled={disabled} type={type}>
        {type === 'email' && <EmailIcon />}
        {type === 'search' && <SearchIcon />}
        {register ? (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={showPassword ? 'text' : type}
            {...register(id)}
            autoFocus={isFocused}
            autoComplete={autoComplete}
          />
        ) : (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={showPassword ? 'text' : type}
            value={value || ''}
            onChange={onChange}
            autoFocus={isFocused}
            autoComplete={autoComplete}
          />
        )}
        {type === 'email' && errorMessage && (
          <span>
            <AttentionIcon />
          </span>
        )}
        {type === 'search' && value && (
          <CloseIcon onClick={() => onClear && onClear()} />
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? (
              <EyeClosedIcon width={18} height={18} />
            ) : (
              <EyeOpenIcon width={18} height={18} />
            )}
          </button>
        )}
      </InputWrapper>
      {hint ||
        (errorMessage && <span>{errorMessage ? errorMessage : hint}</span>)}
    </StyledInput>
  );
};

export default memo(Input);
