import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import { errorSlice, loadingSlice } from 'common';
import {
  UsersSlice,
  AdminOwnerSlice,
  CustomerSupportSlice,
  TechSupportSlice,
  PatientsSlice,
  ProfessionalsSlice,
  InternalUsersSlice,
  InsurancesSlice,
  StatisticsSlice,
  PaginatedSpecializationsSlice,
  UserResetPasswordSlice,
  ClinicsManagementSlice,
  ImportProfessionalsSlice,
  CountriesSlice,
} from './reducers';
import { ResetStateActionType } from 'application/constants/store';

const reducer = combineReducers({
  error: errorSlice.reducer,
  loading: loadingSlice.reducer,
  users: UsersSlice,
  adminOwner: AdminOwnerSlice,
  customerSupport: CustomerSupportSlice,
  techSupport: TechSupportSlice,
  patients: PatientsSlice,
  professionals: ProfessionalsSlice,
  internalUsers: InternalUsersSlice,
  insurances: InsurancesSlice,
  statistics: StatisticsSlice,
  paginatedSpecializations: PaginatedSpecializationsSlice,
  userResetPassword: UserResetPasswordSlice,
  clinicsManagement: ClinicsManagementSlice,
  importProfessionals: ImportProfessionalsSlice,
  countries: CountriesSlice,
});

const rootReducer: typeof reducer = (
  state: RootState | undefined,
  action: AnyAction,
) => {
  if (action.type === ResetStateActionType) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
