import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ClinicsManagementActions,
  InternalUsersActions,
  ProfessionalsActions,
  UsersActions,
} from 'application/constants/actions';

export const initialState = {
  error: '',
  isErrorModalVisible: false,
};

const excludeTypes = (action: PayloadAction<any>) => {
  switch (action.type) {
    case `${UsersActions.usersLogin}/rejected`:
    case `${UsersActions.createPassword}/rejected`:
    case `${UsersActions.changePasswordSaas}/rejected`:
    case `${ProfessionalsActions.loginAsUser}/rejected`:
    case `${InternalUsersActions.createInternalUser}/rejected`:
    case `${ClinicsManagementActions.updateClinic}/rejected`:
      return false;
    default:
      return true;
  }
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setIsErrorModalVisible: (state) => {
      state.isErrorModalVisible = false;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected') && excludeTypes(action),
      (state, action) => {
        console.log(action);
        state.isErrorModalVisible = true;
      },
    );
  },
});

export const { setIsErrorModalVisible } = errorSlice.actions;
