import { useTranslation } from 'react-i18next';
import { PathNames } from 'application/routes/constants';
import { getUserRole } from 'application/sessionStorage/auth';
import { UserRoles } from 'application/constants/userRoles';
import { ReactComponent as StatisticsIcon } from 'features/feature-aside-menu/assets/statistics.svg';
import { ReactComponent as UsersManagementIcon } from 'features/feature-aside-menu/assets/users-managment.svg';
import { ReactComponent as DatabasesIcon } from 'features/feature-aside-menu/assets/databases.svg';
import { ReactComponent as ManagingSubscriptionsIcon } from 'features/feature-aside-menu/assets/managing-subscriptions.svg';
import { ReactComponent as BillingIcon } from 'features/feature-aside-menu/assets/billing.svg';
import { ReactComponent as CustomerSupportIcon } from 'features/feature-aside-menu/assets/customer-support.svg';
import { ReactComponent as PasswordIcon } from 'features/feature-aside-menu/assets/password.svg';
import { ReactComponent as ClinicsIcon } from 'features/feature-aside-menu/assets/home-work.svg';

export const useMenuList = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const isAdminOwner = userRole === UserRoles.adminOwner;

  return [
    ...(isAdminOwner
      ? [
          {
            path: PathNames.statistics,
            name: t('aside_menu.statistics'),
            image: StatisticsIcon,
          },
        ]
      : []),
    {
      path: PathNames.usersManagement,
      name: t('aside_menu.users_management'),
      image: UsersManagementIcon,
    },
    {
      path: PathNames.databases,
      name: t('aside_menu.databases'),
      image: DatabasesIcon,
    },
    {
      path: process.env.REACT_APP_MANAGING_SUBSCRIPTIONS_STRIPE_URL || '',
      name: t('aside_menu.managing_subscriptions'),
      image: ManagingSubscriptionsIcon,
    },
    {
      path: PathNames.clinics,
      name: t('aside_menu.clinics_management'),
      image: ClinicsIcon,
    },
    {
      path: PathNames.billing,
      name: t('aside_menu.billing'),
      image: BillingIcon,
    },
    {
      path: PathNames.customerSupport,
      name: t('aside_menu.customer_support'),
      image: CustomerSupportIcon,
    },
    {
      path: PathNames.password,
      name: t('aside_menu.password'),
      image: PasswordIcon,
    },
  ];
};
