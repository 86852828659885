import React, { FC, JSXElementConstructor } from 'react';
import { MenuItemStyled } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathNames } from 'application/routes';
import { DocBaySupportLink } from 'application';

interface MenuItemProps {
  path: string;
  name: string;
  Image?: JSXElementConstructor<any>;
}

const MenuItem: FC<MenuItemProps> = ({ path, name, Image }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPage = location.pathname === path;
  const isCustomerSupport = path === PathNames.customerSupport;
  const isManagingSubscriptions =
    path === process.env.REACT_APP_MANAGING_SUBSCRIPTIONS_STRIPE_URL;
  const isClinicsItem = path === PathNames.clinics;

  const handleChangePath = () => {
    if (!isCurrentPage) {
      navigate(path);
    }
  };

  return (
    <MenuItemStyled>
      {isCustomerSupport || isManagingSubscriptions ? (
        <a
          href={isCustomerSupport ? DocBaySupportLink : path}
          target={'_blank'}
          rel="noreferrer"
        >
          {Image ? (
            <div className="menu_item">
              <Image />
              <p>{name}</p>
            </div>
          ) : (
            name
          )}
        </a>
      ) : (
        <button
          onClick={handleChangePath}
          className={`${isCurrentPage ? 'active' : ''}`}
        >
          {Image ? (
            <div className={`menu_item ${isClinicsItem ? 'fill-icon' : ''}`}>
              <Image />
              <p>{name}</p>
            </div>
          ) : (
            name
          )}
        </button>
      )}
    </MenuItemStyled>
  );
};

export default MenuItem;
